<template>
	<div class="pt-1 px-1">
		<!-- ------------------------------------------------- -->
		<!-- CLOSE -->
		<!-- ------------------------------------------------- -->
		<div class="flex justify-end">
			<button
				v-if="stage.options?.closable"
				class="d-btn d-btn-sm d-btn-ghost d-btn-circle m-0"
				@click="stage.options?.on?.close()"
			>
				<icon icon="close"></icon>
			</button>
		</div>

		<div class="px-4 pb-4" :class="{ 'pt-4': !stage.options?.closable }">
			<div class="flex flex-wrap justify-between items-center">
				<div class="flex-1 flex justify-between items-start gap-2">
					<!-- ------------------------------------------------- -->
					<!-- HEADER -->
					<!-- ------------------------------------------------- -->
					<div class="space-y-2">
						<div class="flex items-center gap-4">
							<!-- ------------------------------------------------- -->
							<!-- (SUPER)-TITLE -->
							<!-- ------------------------------------------------- -->
							<div>
								<div v-if="stage.hero.supertitle" class="flex items-center gap-2">
									<p :class="stage.hero.supertitleClass ?? 'text-sm'">{{ stage.hero.supertitle }}</p>
									<portal-target :name="`stage-${index}-supertitle-menu`"></portal-target>
								</div>

								<p class="text-3xl font-bold">{{ stage.hero.title }}</p>
							</div>

							<div>
								<portal-target :name="`stage-${index}-menu`"></portal-target>
							</div>

							<!-- ------------------------------------------------- -->
							<!-- MENU -->
							<!-- ------------------------------------------------- -->
							<portal :to="stage.hero.supertitle ? `stage-${index}-supertitle-menu` : `stage-${index}-menu`">
								<div class="flex items-center gap-4">
									<div
										class="d-tooltip d-tooltip-bottom"
										v-for="(btn, index) in stage.hero.buttons?.items?.filter(
											(i) => i.condition == null || i.condition == true
										)"
										:key="index"
										:data-tip="btn.tooltip ?? null"
									>
										<button
											class="d-btn"
											:class="btn.class"
											@click="
												() => {
													if (btn.disabled) return
													btn.click()
												}
											"
										>
											<icon :icon="btn.icon" :cls="[btn.iconClass]" v-if="btn.icon">{{ btn.label }}</icon>
											<span v-else>{{ btn.label }}</span>
										</button>
									</div>

									<tippy
										v-if="
											stage.hero.menu &&
											stage.hero.menu?.items?.length &&
											(stage.hero.menu.condition == null || stage.hero.menu.condition == true)
										"
										:ref="
											(el) => {
												refTippy = el
											}
										"
									>
										<template #trigger>
											<button class="d-btn d-btn-xs d-btn-circle">
												<icon icon="dots-horizontal" cls="w-4 h-4 text-primary"></icon>
											</button>
										</template>
										<ul class="min-w-52 d-menu w-full rounded-box">
											<li
												v-for="(item, index) in stage.hero.menu.items.filter(
													(i) => i.condition == null || i.condition == true
												)"
												:key="index"
												:class="{ 'd-disabled': item.disabled }"
											>
												<a
													@click="
														() => {
															refTippy.tip.hide()
															if (item.disabled) return
															item.link()
														}
													"
												>
													<icon :icon="item.icon" :cls="item.iconClass">{{ item.label }}</icon>
												</a>
											</li>
										</ul>
									</tippy>
								</div>
							</portal>
						</div>

						<!-- ------------------------------------------------- -->
						<!-- SUBTITLE & DESCRIPTION -->
						<!-- ------------------------------------------------- -->
						<p v-if="stage.hero.subtitle" :class="stage.hero.subtitleClass">{{ stage.hero.subtitle }}</p>
						<p class="text-sm" :class="stage.hero.descriptionClass" v-if="stage.hero.description">
							{{ stage.hero.description }}
						</p>

						<!-- ------------------------------------------------- -->
						<!-- BADGES-->
						<!-- ------------------------------------------------- -->
						<div class="flex items-center gap-2">
							<span
								class="d-badge"
								:class="badge.class"
								v-for="(badge, index) in stage.hero.badges?.filter((b) => b.condition == null || b.condition == true)"
								:key="index"
							>
								<template v-if="badge.icon"><icon :icon="badge.icon"></icon></template>
								<template v-else>{{ badge.name }}</template>
							</span>
						</div>
					</div>
				</div>

				<!-- ------------------------------------------------- -->
				<!-- PORTAL -->
				<!-- ------------------------------------------------- -->
				<portal-target :name="`stage-${index}-hero`" multiple></portal-target>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, inject } from 'vue'

const props = defineProps({
	stage: {
		type: Object,
	},
	index: {
		type: Number,
	},
})

const rysqer = inject('rysqer')
</script>
