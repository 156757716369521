<template>
	<div v-if="localDefinition">
		<Vueform
			:size="size"
			ref="refForm"
			v-bind="localDefinition"
			v-model="form"
			sync
			:disabled="disabled"
			:endpoint="false"
			@change="$emit('input', form)"
			@submit="$emit('submit', form)"
			:columns="{ container: container }"
			:add-class="[gutterClass]"
		/>
	</div>
</template>

<script setup>
import { ref, watch, computed, inject } from 'vue'

const props = defineProps({
	definition: {
		type: Object,
	},
	value: {
		type: Object,
	},
	disabled: {
		type: Boolean,
	},
	container: {
		type: Number,
		default: 12,
	},
	size: {
		type: String,
		default: 'md',
	},
	gutter: {
		type: String,
		default: null,
	},
})

const emit = defineEmits(['change', 'submit', 'input'])

const rysqer = inject('rysqer')

const refForm = ref(null)

const form = ref({})

const localDefinition = ref()

const gutterClass = computed(() => {
	if (props.gutter == 'narrow') {
		return 'vf-gutter-narrow'
	}

	return null
})

const redefine = () => {
	let tmpDef = { ...props.definition }

	const transformObject = (obj) => {
		for (const key in obj) {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				transformObject(obj[key])
			}

			if (obj.hasOwnProperty('type')) {
				Object.assign(obj, {
					disabled: props.disabled,
				})
			}
		}
	}

	transformObject(tmpDef)

	localDefinition.value = { schema: tmpDef }
}

watch(
	() => props.value,
	() => {
		if (refForm.value) refForm.value.resetValidators()
		if (props.value) form.value = { ...props.value }
	},
	{ immediate: true }
)

watch(
	() => props.definition,
	() => {
		if (props.definition) redefine()
	},
	{ immediate: true }
)

defineExpose({ refForm, form })
</script>

<style lang="scss">
.vf-gutter-narrow *,
.vf-gutter-narrow *:before,
.vf-gutter-narrow *:after,
.vf-gutter-narrow:root {
	--vf-gutter: 12px;
	--vf-font-size: 16px;
}
</style>
