<template>
	<div class="VIEWS h-inherit flex">
		<div class="flex-1 bg-base-100">
			<component :is="rysqer.active.view.component" :stage="rysqer.stage" :view="rysqer.active.view"> </component>
			<splitpanes class="h-full w-full default-theme" push-other-panes>
				<!-- :class="{ hidden: rysqer.sizes[index] == 0 }" -->
				<pane
					v-for="(stage, index) in rysqer.stages"
					:key="stage.uuid"
					:size="rysqer.sizes[index]"
					class="PANE relative flex flex-col"
				>
					<div
						class="absolute inset-0 z-50 bg-base-100 bg-opacity-50 backdrop-blur-md flex flex-col justicy-center items-center"
						v-if="stage.busy"
					>
						<spinner class="flex-1"></spinner>
					</div>

					<rysqer-hero :stage="stage" :index="index" :class="[stage.hero.class]" v-if="stage.hero.title || stage.options?.closable" />

					<div class="px-4 py-2 border-b" v-if="stage.scenes.length > 1">
						<rysqer-stage-tabs :stage="stage" />
					</div>

					<portal-target :name="`no-scroll-${index}`" multiple> </portal-target>

					<div
						class="SCENE relative flex-1 overflow-y-auto flex flex-col"
						:class="stage.scene.class"
						v-if="stage.scene"
						:key="stage.scene.id"
					>
						<component
							class="SCENE COMPONENT"
							:is="stage.scene.component"
							:key="stage.uuid + stage.scene.id"
							:stage="stage"
							:view="stage.scene.view"
							:scene="stage.scene"
							:options="stage.scene.options"
							:resources="stage.scene.resources"
						>
						</component>

						<component
							v-for="section in stage.scene.sectionsArray.filter((a) => {
								return (a.condition == null || a.condition == true) && (a.visible == null || a.visible == true)
							})"
							:key="stage.uuid + '-' + stage.scene.id + '-' + section.id"
							:class="['SECTION', section.class]"
							:is="section.component"
							:stage="stage"
							:view="stage.scene.view"
							:scene="stage.scene"
							:section="section"
							:options="{ ...stage.scene.options, ...section.options }"
							:resources="stage.scene.resources"
							:ref="(el) => (section.ref = el)"
						/>

						<!-- <rysqer-section
							v-for="section in stage.scene.sectionsArray.filter((a) => {
								return (a.condition == null || a.condition == true) && (a.visible == null || a.visible == true)
							})"
							class="SECTION"
							:key="stage.uuid + '-' + stage.scene.id + '-' + section.id"
							:stage="stage"
							:view="stage.scene.view"
							:scene="stage.scene"
							:section="section"
							:options="{ ...stage.scene.options, ...section.options }"
							:resources="stage.scene.resources"
						/> -->
					</div>
				</pane>
			</splitpanes>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue'

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import RysqerHero from './Hero'
import RysqerSection from '@/layout/Section/Section'

const rysqer = inject('rysqer')

const panes = ref()

const activePaneIndex = ref(-1) // No pane is active at start

const refTippy = ref(null)

function handlePaneFocus(index) {
	activePaneIndex.value = index
}

function handleResize(event) {
	rysqer.sizes.value = event.map((pane) => pane.size)
}
</script>

<style lang="scss">
.splitpanes.default-theme .splitpanes__splitter {
	@apply bg-inherit border border-base-200 bg-base-200 w-px mr-0 ml-0;
}

.splitpanes.default-theme .splitpanes__pane {
	@apply bg-inherit;
}
.splitpanes__pane {
	@apply overflow-y-hidden;
}

.splitpanes__splitter {
	transition: none !important;
}
.splitpanes__pane {
	transition: none !important;
}
// .splitpanes .splitpanes__pane {
// 	transition: none;
// }
</style>
