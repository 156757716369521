var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pt-1 px-1"},[_c('div',{staticClass:"flex justify-end"},[(_vm.stage.options?.closable)?_c('button',{staticClass:"d-btn d-btn-sm d-btn-ghost d-btn-circle m-0",on:{"click":function($event){_vm.stage.options?.on?.close()}}},[_c('icon',{attrs:{"icon":"close"}})],1):_vm._e()]),_c('div',{staticClass:"px-4 pb-4",class:{ 'pt-4': !_vm.stage.options?.closable }},[_c('div',{staticClass:"flex flex-wrap justify-between items-center"},[_c('div',{staticClass:"flex-1 flex justify-between items-start gap-2"},[_c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('div',[(_vm.stage.hero.supertitle)?_c('div',{staticClass:"flex items-center gap-2"},[_c('p',{class:_vm.stage.hero.supertitleClass ?? 'text-sm'},[_vm._v(_vm._s(_vm.stage.hero.supertitle))]),_c('portal-target',{attrs:{"name":`stage-${_vm.index}-supertitle-menu`}})],1):_vm._e(),_c('p',{staticClass:"text-3xl font-bold"},[_vm._v(_vm._s(_vm.stage.hero.title))])]),_c('div',[_c('portal-target',{attrs:{"name":`stage-${_vm.index}-menu`}})],1),_c('portal',{attrs:{"to":_vm.stage.hero.supertitle ? `stage-${_vm.index}-supertitle-menu` : `stage-${_vm.index}-menu`}},[_c('div',{staticClass:"flex items-center gap-4"},[_vm._l((_vm.stage.hero.buttons?.items?.filter(
										(i) => i.condition == null || i.condition == true
									)),function(btn,index){return _c('div',{key:index,staticClass:"d-tooltip d-tooltip-bottom",attrs:{"data-tip":btn.tooltip ?? null}},[_c('button',{staticClass:"d-btn",class:btn.class,on:{"click":() => {
												if (btn.disabled) return
												btn.click()
											}}},[(btn.icon)?_c('icon',{attrs:{"icon":btn.icon,"cls":[btn.iconClass]}},[_vm._v(_vm._s(btn.label))]):_c('span',[_vm._v(_vm._s(btn.label))])],1)])}),(
										_vm.stage.hero.menu &&
										_vm.stage.hero.menu?.items?.length &&
										(_vm.stage.hero.menu.condition == null || _vm.stage.hero.menu.condition == true)
									)?_c('tippy',{ref:(el) => {
											_vm.refTippy = el
										},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('button',{staticClass:"d-btn d-btn-xs d-btn-circle"},[_c('icon',{attrs:{"icon":"dots-horizontal","cls":"w-4 h-4 text-primary"}})],1)]},proxy:true}],null,false,456535736)},[_c('ul',{staticClass:"min-w-52 d-menu w-full rounded-box"},_vm._l((_vm.stage.hero.menu.items.filter(
												(i) => i.condition == null || i.condition == true
											)),function(item,index){return _c('li',{key:index,class:{ 'd-disabled': item.disabled }},[_c('a',{on:{"click":() => {
														_vm.refTippy.tip.hide()
														if (item.disabled) return
														item.link()
													}}},[_c('icon',{attrs:{"icon":item.icon,"cls":item.iconClass}},[_vm._v(_vm._s(item.label))])],1)])}),0)]):_vm._e()],2)])],1),(_vm.stage.hero.subtitle)?_c('p',{class:_vm.stage.hero.subtitleClass},[_vm._v(_vm._s(_vm.stage.hero.subtitle))]):_vm._e(),(_vm.stage.hero.description)?_c('p',{staticClass:"text-sm",class:_vm.stage.hero.descriptionClass},[_vm._v(" "+_vm._s(_vm.stage.hero.description)+" ")]):_vm._e(),_c('div',{staticClass:"flex items-center gap-2"},_vm._l((_vm.stage.hero.badges?.filter((b) => b.condition == null || b.condition == true)),function(badge,index){return _c('span',{key:index,staticClass:"d-badge",class:badge.class},[(badge.icon)?[_c('icon',{attrs:{"icon":badge.icon}})]:[_vm._v(_vm._s(badge.name))]],2)}),0)])]),_c('portal-target',{attrs:{"name":`stage-${_vm.index}-hero`,"multiple":""}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }